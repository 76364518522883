.card {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.card-header {
  text-align: center;
  margin-bottom: 20px;
}

.signature-image {
  width: 100px;
}

.header-large {
  font-size: 24px;
  margin: 10px 0;
}

.header-medium {
  font-size: 20px;
  margin: 10px 0;
}
.result-date{

text-align:left;
padding: -5px;
}

.text-normal {
  font-size: 16px;
  margin: 5px 0;
}

.student-info, .grades {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.student-info td, .grades th, .grades td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.grades th, .grades td {
  text-align: center;
}

.placeholder {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 2px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.signature {
  text-align: center;
  margin-bottom: 20px;
}

.signature-text {
  text-align: end;
  margin-top: 10px;
}

.signature-image-right {
  width: 300px;
  text-align: end;
}

.buttons {
  text-align: center;
  width: 100%;
}

.btn {
  padding: 10px 20px;
  margin: 5px;
  font-size: 20px;
  background-color: #513c72;
  color: white;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #412b61;
}

.black-table th,
.black-table td {
  border: 1px solid #000;
  color: #000;
}
