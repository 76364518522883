/* result.css */
.registration-form {
  max-width: 600px;
   margin-left: 400px;
   margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20;
  }
  
  .registration-form-header {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
  }
  
  .logo {
    width: 60px;
margin-top: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select,
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .footer-text {
    text-align: center;
    padding: 20px;
    color: #fff;
    margin-top: 10;
  }
  .form-control:hover {
    background-color: #e0e0e0;
    border-color: #007bff;
  }
  .form-control2:hover {
    background-color: #e0e0e0;
    border-color: #007bff;
  }
  .form-control:focus {
  background-color: #fff;
  border-color: #0056b3;
  outline: none;
}
.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}
.form-control2 {
  width: 96%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

  